<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="vendor && vendor.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить поставщика</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editVendorForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="vendor.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус</label>
                <md-select v-model="vendor.is_active" @focus="reset">
                  <md-option
                    v-for="item in activeOptions"
                    :key="item.id"
                    :value="item.id"
                    placeholder="Статус"
                  >
                    {{ item.title }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editVendor">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      activeOptions: [
        {
          id: 0,
          title: "Не активен",
        },
        {
          id: 1,
          title: "Активен",
        },
      ],
      vendor: {},
    };
  },

  computed: {
    ...mapState({
      initVendor: (state) => state.vendors.vendor,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getVendor(+this.$route.params.id);
    if (success) {
      this.$set(this.vendor, "id", this.initVendor.id);
      this.$set(this.vendor, "name", this.initVendor.name);
      this.$set(this.vendor, "is_active", this.initVendor.is_active);
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("vendors", ["getVendor", "updateVendor"]),

    async editVendor() {
      const isValid = await this.$refs.editVendorForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateVendor({
        id: this.initVendor.id,
        vendor: this.vendor,
      });

      if (success) {
        this.$router.push("/vendors");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>